import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Footer from "../components/Footer";

function Termsandcondition() {
  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  const handleClose = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="shadow-xl bg-pink-100 w-full fixed top-0 z-10" ref={topele}>
        <div className="flex  items-center ">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
          <div className="flex items-center  ">

          </div>
        </div>
      </div>
      <h1 className="mt-20 font-bold text-2xl font-serif mx-3">Terms & Conditions</h1>
      <div>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">1. Introduction</h3>
        <p className="mb-5 mx-3 text-justify">Welcome to Band Baja Baraati. By accessing this website, you agree to comply with and be bound by the following terms and conditions of use. Please read these terms carefully before using our website.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">2. Use of Website</h3>
        <p className="mb-5 mx-3 text-justify">Your use of Band Baja Baraati is subject to the following terms of use:</p>
        <ul class="list-disc list-inside mx-4">
          <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
          <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
          <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</li>
          <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
          <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
          <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
          <li>From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
          <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of [your country].
          </li>
        </ul>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">3. Privacy Policy</h3>
        <p className="mb-5 mx-3 text-justify">Please refer to our Privacy Policy section above for information on how we collect, use, and disclose personal information.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">4. Changes to Terms</h3>
        <p className="mb-5 mx-3 text-justify">We reserve the right to amend these terms and conditions at any time. Any changes will be effective immediately upon posting on this website. Your continued use of the website after any such changes constitutes acceptance of the new terms and conditions.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">5. Contact Us</h3>
        <p className="mb-5 mx-3 text-justify">If you have any questions or concerns about these terms and conditions, please contact us at contact@bandbaajabaarati.com.</p>
      </div>

      <Footer />

    </>
  );
}

export default Termsandcondition;