import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { MdOutlineArrowBackIos } from "react-icons/md";


function Weddingdetails() {
  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  const handleClose = () => {
    navigate(-1);
  };


  const Inquery = localStorage.getItem("submittedMessage") || "Inquery not found";

  return (
    <>
      <div className="shadow-xl bg-pink-100 w-full fixed top-0 z-10" ref={topele}>
        <div className="flex  items-center ">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
          <div className="flex items-center  ">

          </div>
        </div>
      </div>


      <h2 className="mt-24 font-bold text-2xl font-serif text-center mx-3 mb-3">Inquiry</h2>
      <div className="mt-6 mb-3 mx-3 text-center">
        <p className="font-serif text-base text-justify">{Inquery}</p>
      </div>

    </>
  );
}

export default Weddingdetails;