import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import Footer from "../components/Footer";

function Contact() {
  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  const handleClose = () => {
    navigate(-1);
  };
  return (
    <>
      <div className=" shadow-lg" ref={topele}>
        <div className="flex  items-center ">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
          <div className="flex items-center  ">

          </div>
        </div>
      </div>

      <section className="bg-pink-100 dark:bg-gray-900">
        <div className="container px-6 py-12 mx-auto">
          <div>
            <p className="text-custom-pink text-3xl font-serif font-bold dark:text-custom-pink">Contact us</p>

            <h1 className="mt-2 text-xl font-semibold text-gray-800 md:text-3xl dark:text-white font-serif">Chat to our friendly team</h1>

            <p className="mt-3 text-gray-500 dark:text-gray-400">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
          </div>

          <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
            <div className="grid grid-cols-1  gap-12 justify-center">
              <div>
                <span className="inline-block  text-custom-pink rounded-full bg-pink-100/80 dark:bg-pink-800">
                  <AiOutlineMail className="text-xl" />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Our friendly team is here to help.</p>
                <Link to={`mailto:example@outlook.com`}>
                  <p className="mt-2 text-sm text-custom-pink dark:text-custom-pink">care@bandbaajabaarati.com</p>
                </Link>
              </div>
              <div>
                <span className="inline-block  text-custom-pink rounded-full bg-pink-100/80 dark:bg-pink-800">
                  <IoCallOutline className="text-xl" />
                </span>
                <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Phone</h2>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Mon-Fri from 8am to 5pm.</p>
                <Link to={`tel:5865248525`}>
                  <p className="mt-2 text-sm text-custom-pink dark:text-custom-pink">8440011500</p>
                </Link>
              </div>
              <div>
                <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white mb-3">Follow Us :</h2>
                <div className="flex ms-2">
                  <Link to="https://www.facebook.com/people/Band-Baaja-Baarati/61563147146010/">
                    <img src="assets\images\facebook.png" alt="" width="25px" className="me-2 bg-pink-100 border rounded-full" />
                  </Link>
                  <Link to="https://www.instagram.com/bandbaajabaaratiofficial/" className="text-white ">
                    <img src="assets\images\instagram.png" alt="" width="30px" className="me-2 bg-pink-100 border rounded-full" />
                  </Link>
                  <Link to="https://in.pinterest.com/bandbajabaraati/" className="text-white ">
                    <img src="assets\images\pinterest.png" alt="" width="25px" className="me-2  bg-pink-100 border rounded-full" />
                  </Link>
                </div>
              </div>

            </div>
            <div className="p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8">
              <form>
                <div className="-mx-2 md:items-center md:flex">
                  <div className="flex-1 px-2">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">First Name</label>
                    <input type="text" placeholder="John " className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-pink-100 border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-pink-200 dark:focus:border-pink-200 focus:ring-pink-200 focus:outline-none focus:ring focus:ring-opacity-40" />
                  </div>

                  <div className="flex-1 px-2 mt-4 md:mt-0">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Last Name</label>
                    <input type="text" placeholder="Doe" className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-pink-100 border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-pink-200 dark:focus:border-pink-200 focus:ring-pink-200 focus:outline-none focus:ring focus:ring-opacity-40" />
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address</label>
                  <input type="email" placeholder="johndoe@example.com" className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-pink-100 border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-pink-200 dark:focus:border-pink-200 focus:ring-pink-200 focus:outline-none focus:ring focus:ring-opacity-40" />
                </div>

                <div className="w-full mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Message</label>
                  <textarea className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-pink-100 border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-pink-200 dark:focus:border-pink-200 focus:ring-pink-200 focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Message"></textarea>
                </div>

                <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-custom-pink rounded-lg hover:bg-pink-400 focus:outline-none focus:ring focus:ring-pink-300 focus:ring-opacity-50">
                  Send message
                </button>
              </form>
            </div>




          </div>
        </div>
      </section>

      <Footer />

    </>
  );
}

export default Contact;