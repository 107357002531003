import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoLocationOutline } from "react-icons/io5";
import { scrollToTop } from "../utils/scrollToTop";
import { MdOutlineArrowBackIos } from "react-icons/md";
import axios from 'axios';
import config from '../config';

const MyVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  });

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.post(`${config.API_BASE_URL}/my-vendors`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setVendors(response.data.response); // Assuming data is an array of vendors
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  if (loading) return <p className='text-center mt-10'>Loading...</p>;
  if (error) return <p className='text-center mt-10'>Error: {error}</p>;

  return (
    <>
      <div className="shadow-xl bg-pink-100 w-full fixed top-0 z-10" ref={topele}>
        <div className="flex items-center">
          <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
            <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
          </button>
        </div>
      </div>


      <h2 className="font-bold text-2xl font-serif text-center mx-3 mt-20">My Vendors</h2>

      <div className="w-full mt-5">
        {Array.isArray(vendors) && vendors.length > 0 ? (
          vendors.map(vendor => (
            <div key={vendor.id} className="overflow-hidden rounded-lg bg-pink-100">
              <div className="rounded-md border mx-2">
                <img
                  src={`https://bandbaajabaarati.com/vendorbusiness/banner_image/${vendor.business.banner_image}`}
                  alt={vendor.name}
                  className="rounded-md object-cover w-full"
                />
                <div className="p-4">
                  <div className="flex items-center">
                    <h1 className="font-semibold me-32 text-2xl">{vendor.business.title}</h1>
                  </div>
                  <div className="flex items-center text-xl text-gray-600">
                    <IoLocationOutline className="mr-1" />
                    <p>{vendor.business.location}</p>
                  </div>
                </div>
                <div>
                  <h1 className="text-base line-clamp-4 text-justify mx-2">{vendor.business.about}</h1>
                </div>
                <div className="p-4 border-t w-full">
                  <Link to="/form">
                    <button className="bg-custom-pink text-white px-20 py-2 text-xl border rounded-lg w-full">
                      Request Quote
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className='text-center mt-10'>No vendors available</p>
        )}
      </div>
    </>
  );
};

export default MyVendors;
