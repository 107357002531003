import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { MdOutlineArrowBackIos } from "react-icons/md";
import Footer from "../components/Footer";
import { LuDot } from "react-icons/lu";

function Policy() {
  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  const handleClose = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="shadow-xl bg-pink-100 w-full fixed top-0 z-10" ref={topele}>
        <div className="flex  items-center ">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
          <div className="flex items-center  ">
          </div>
        </div>
      </div>
      <h1 className="mt-20 font-bold text-3xl font-serif mx-3">Privacy Policy</h1>
      <div>
        <p className="mt-8 mx-3 text-justify">
          This privacy policy applies to your use of the Band Baja Baraati website located at<span className="underline decoration-2 font-bold text-custom-pink"> www.BandBajaBaarati.com </span> (hereafter known as Band Baja Baraati), but does not apply to any third-party sites that may be linked to them, or any relationships you may have with the businesses listed on Band Baja Baraati. The terms "we", "us", and "Band Baja Baraati" refer to Band Baja Baraati.com and the terms "you" and "your" refer to you, as a user of Band Baja Baraati. The term "personal information" means information that you provide to us which personally identifies you to be contacted or identified, such as your name, phone number, email address, and any other data that is tied to such information. By using Band Baja Baraati, you agree to be bound by the terms of this privacy policy.
        </p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">1. Information we collect and how we use it</h3>
        <p className="mb-5 mx-3 text-justify">We are committed to limiting the collection of personal information to what is necessary to fulfill the purposes identified below. If we intend to use your information for purposes other than those stated here, we will seek your consent beforehand.</p>

        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Account information:
        </span> When you create an account on Band Baja Baraati to access our full range of services, we collect and store personal information such as your name and email address. We use your email address to send you updates, news, and newsletters (if you opt to subscribe) and to facilitate communication with other users (such as sending compliments, personal messages, or favorite reviews). </p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Telephone numbers:
        </span> Any telephone number provided as part of your account information will not be shared with third parties unless explicitly authorized by you. However, please note that business contact information may be made public at the discretion of the business proprietor. Band Baja Baraati is not responsible for the misuse of this information by such businesses or related parties. </p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Submissions:
        </span>  We store and may publicly display the submissions you make, including business ratings, reviews, photos, compliments, and information displayed on your account profile, to provide our services effectively. Your submissions will be displayed along with your account name (first name and last initial).</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Feedback:
        </span>  If you contact us to provide feedback, register a complaint, or ask a question, we will record any personal information and other content you provide to respond effectively.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Activity:
        </span>  We collect information about your usage of Band Baja Baraati, such as searches, viewed pages, browser type, IP address, location, requested URL, referring URL, and timestamp information. We utilize this data to administer Band Baja Baraati, enhance our services, and conduct statistical analyses of user behavior and interests. This aggregate information does not personally identify you.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Cookies:
        </span> We use cookies to enhance your browsing experience and improve the quality of our service. Cookies are small pieces of information sent to your browser while you visit our site. You can manage cookies through your browser settings, but please note that disabling cookies may affect the functionality of certain areas of Band Baja Baraati. Third-party tools may also collect anonymous information about your visits to Band Baja Baraati for targeted advertising purposes. Please refer to your browser's help documentation for information on managing cookies.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Enforcement:
        </span>  We may use the information collected, including personal information, to investigate, enforce, and apply our Terms of Service and Privacy Policy.</p>






        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">2. Transfer of information</h3>
        <p className="mb-5 mx-3 text-justify">We may distribute your submissions to third parties, as permitted pursuant to our Terms of Service. These third parties may publicly display or publish your submissions on their websites and media platforms. When sharing your submissions with third parties, we typically include your account name and details.</p>
        <p className="mb-5 mx-3 text-justify">We may share your personal information with third parties to provide you with services offered through Band Baja Baraati, including quality assurance testing, account creation, technical and customer support, and specific services like contact synchronization with other software applications, as per your instructions. These third parties are obligated to use your personal information solely for providing the requested services.</p>
        <p className="mb-5 mx-3 text-justify">We may also share your personal information with our parent company, subsidiaries, joint ventures, or other entities under common control ("Affiliates"), both currently and in the future, subject to their agreement to adhere to this Privacy Policy.</p>
        <p className="mb-5 mx-3 text-justify">If Band Baja Baraati is acquired by another company or if our assets are acquired, your personal information will be transferred to the acquiring company, which will assume the rights and responsibilities regarding that information as outlined in this Privacy Policy.</p>
        <p className="mb-5 mx-3 text-justify">We may disclose your personal information to third parties in good faith belief that such disclosure is reasonably necessary to: (a) address suspected illegal activities; (b) enforce our Terms of Service and Privacy Policy; (c) comply with legal requirements such as search warrants, subpoenas, statutes, or court orders; or (d) protect our rights, reputation, property, or those of our users, affiliates, or the public. Please note that we are not obligated to question or challenge the validity of any search warrant, subpoena, or similar governmental request.</p>
        <p className="mb-5 mx-3 text-justify">We may also share aggregate information about user behavior with third parties in connection with actual or potential business relationships, such as advertisers and content distributors. For instance, we may disclose the number of users exposed to or clicking on advertising banners.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">3.Other Information</h3>
        <p className="mb-5 mx-3 text-justify">You have the option to provide us with another person's email address to invite them to create an account on Band Baja Baraati and become your friend. We use this information to send invitations and, if necessary, reminders to the recipient. By providing us with another person's email address, you confirm that you have obtained their consent to disclose their personal information to us.
          All invitees are given the choice to decline further invitations. We may disclose this information if legally required to do so, such as to comply with legal process as described in earlier sections of this policy.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">4. Links</h3>
        <p className="mb-5 mx-3 text-justify">Band Baja Baraati may contain links to third-party websites with which we have no affiliation. Unless otherwise stated, we do not share your personal information with these third parties and are not responsible for their privacy practices. We recommend reviewing the privacy policies of all third-party websites you visit.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">5. Controlling your personal data</h3>
        <p className="mb-5 mx-3 text-justify">If you include personal information in your submissions (such as a photo of yourself), third parties may be able to identify you, associate you with your user account, and contact you. Third parties can also associate you with your user account using the member lookup feature on Band Baja Baraati, provided they know your full name and you include distinguishing information in your submission.We recommend refraining from including sensitive information in your submissions that you wish to keep private. Additionally, you can reduce the risk of being personally identified by using a fictitious account name, though this may impact the credibility of your submissions.</p>
        <p className="mb-5 mx-3 text-justify">As a registered user of Band Baja Baraati, you can manage some of your personal information and privacy preferences by accessing the "Account" section of the website. For instance, you can choose not to receive email solicitations by adjusting your newsletter/notification settings under the "Account" tab after signing in.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">6. Security</h3>
        <p className="mb-5 mx-3 text-justify">Your Band Baja Baraati account is password protected, and we employ industry-standard measures to safeguard the personal information stored in our database. Access to your personal information is restricted to employees and contractors who require it to perform their job functions, such as customer service personnel.</p>
        <p className="mb-5 mx-3 text-justify">If you have any inquiries regarding the security measures on Band Baja Baraati, please don't hesitate to contact us. While we take appropriate precautions to prevent unauthorized disclosures of information, we cannot guarantee that your personal information will never be disclosed inconsistently with this Privacy Policy.</p>
        <p className="mb-5 mx-3 text-justify">You acknowledge that Band Baja Baraati is not liable for any intercepted information transmitted over the internet, and you hereby release us from any claims arising from or related to the unauthorized use of intercepted information.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">7. E-mails and opt-out</h3>
        <p className="mb-5 mx-3 text-justify">You can opt out of receiving messages or newsletters from Band Baja Baraati by adjusting your notification settings in the "Account" tab after logging in. Unregistered users who receive emails from us can also opt out by following the instructions provided in our emails.</p>
        <p className="mb-5 mx-3 text-justify">However, please note that despite your email preferences, we may still send you notices regarding updates to our Terms of Use or Privacy Policy.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">8. Terms and modifications to this Privacy Policy</h3>
        <p className="mb-5 mx-3 text-justify">Band Baja Baraati reserves the right to modify this Privacy Policy at any time, and any updates will be posted on this page. If there are any material changes in how we use your personal information, we will notify you by sending an email to the most recent email address you provided us and/or by prominently displaying notice of the changes on our website.</p>
        <p className="mb-5 mx-3 text-justify">These changes will become effective either thirty (30) calendar days after we dispatch an email notice to you or thirty (30) calendar days after we post notice of the changes on our website, whichever occurs earlier. Please ensure that your personal information is kept up to date, particularly your email address. If the email address we have on file for you is invalid or incapable of delivering the notice, our dispatch of the email containing such notice will still constitute effective notification of the described changes.</p>
        <p className="mb-5 mx-3 text-justify">Changes to this Privacy Policy will apply immediately to new users of Band Baja Baraati. By continuing to use our services after being notified of such changes, you acknowledge and agree to be bound by the updated terms and conditions of this Privacy Policy.</p>
        <p className="mb-5 mx-3 text-justify">If you do not consent to the changes in our use of your personal information, you must inform us before the effective date of the changes if you wish to deactivate your account with us. Your continued use of Band Baja Baraati following notification of such changes indicates your acknowledgment of the changes and agreement to abide by the updated terms and conditions.</p>


        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">9. Dispute resolution</h3>
        <p className="mb-5 mx-3 text-justify">If you believe that BandBajaBaarati not adhered to this Privacy Policy you may write to BandBajaBaarati at the following address:<span className="underline decoration-2 font-bold text-custom-pink"> care@BandBajaBaarati.com </span> In your email, please describe in as much detail as possible ways in which you believe the Privacy Policy has not been complied with. We will investigate your complaint promptly.</p>
        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">10. Contacting the website and access to your personal information</h3>
        <p className="mb-5 mx-3 text-justify">Upon written request, subject to certain exceptions, Band Baja Baraati will inform you of the existence, use, and disclosure of your personal information and provide you access to that information. Access requests should be sent to our Privacy Officer using the contact information provided above. When requesting access to your personal information, we may require specific information from you to confirm your identity and right to access, as well as to locate and provide the personal information we hold about you.We may charge a fee for access to your personal information to cover our costs; however, we will notify you of any fees in advance, which will not exceed any maximum fee prescribed by law. If you have any inquiries regarding this Privacy Policy, you can contact us using the provided contact information.</p>
        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">11. Deleting your data</h3>
        <p className="mb-5 mx-3 text-justify">You have the right to share and access your personal information and right to withdraw consent for sharing your personal information at any point of time and right to erase your personal information subject to applicable laws. You can withdraw your consent provided by contacting us. To request to review, update, or delete your personal information, please submit a request on this <span className="underline decoration-2 font-bold text-custom-pink"> link. </span></p>
      </div>
      <Footer />

    </>
  );
}

export default Policy;