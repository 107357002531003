import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';

function Login() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      setIsButtonDisabled(value.length !== 10);
    }
  };

  const handleSendOTP = async () => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/sendotp`, {
        phone_number: mobileNumber,
      });
      if (response.data.success) {
        sessionStorage.setItem('mobileNumber', mobileNumber);
        toast.success('OTP sent successfully.', {
          onClose: () => navigate('/otp')
        });

      } else {
        toast.error('Something went wrong. Please try again.');
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    }
  };

  // const token = sessionStorage.getItem('token');
  // useEffect(() => {
  //   if (token) {
  //     navigate("/home")
  //   }
  // }, [token])

  // const [user, setUser] = useState(null);


  // login check
  useEffect(() => {
    const checkPackageStatus = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch('https://bandbaajabaarati.com/api/save-my-package', {
          method: "post",
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ business_id: 22 })
        });

        console.log(response);
        if (response.data.status !== 'success') {
          navigate('/home');
          // setUser({ user: "Guest", status: "success" });
        }
      } catch (error) {
        console.error('Error checking package status:', error);
        navigate('/login');
      }
    };

    checkPackageStatus();
  }, []);



  return (
    <div className="bg-custom-pink min-h-screen flex flex-col justify-between">
      <ToastContainer autoClose={1000} />
      <div className="mb-2 flex justify-center mt-12">
        <img src="assets/images/logo1.png" alt="Logo" width="150px" />
      </div>
      <div className='mb-10'>
        <div className='flex justify-center'>
          <img src="assets/images/logo2.png" alt="Logo" width="300px" />
        </div>
        <div>
          <p className='text-white text-center text-sm font-serif font-semibold'>India 's Largest wedding company</p>
        </div>
      </div>



      <div className="bg-white border rounded-t-2xl p-6">
        <h1 className="text-black text-3xl font-semibold font-serif">Sign up/Login</h1>
        <p className="text-gray-400 mt-3 mb-12">Enter your mobile number to continue</p>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className="w-full xl:w-1/3 mb-4 mx-auto">
            <input
              className="flex h-12 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-xl placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
              type="number"
              placeholder="Enter Mobile Number"
              value={mobileNumber}
              onChange={handleInputChange}
              autoFocus
              required
            />
          </div>

          <div className="w-full xl:w-1/3 mx-auto">
            <button
              type="button"
              className="h-12 rounded-md bg-custom-pink px-3 py-2 text-xl font-semibold text-white w-full"
              disabled={isButtonDisabled}
              onClick={handleSendOTP}
            >
              Get OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
