import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { RxCross1 } from "react-icons/rx";
import { CgNotes } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { FiShoppingBag } from "react-icons/fi";
import { IoLogOutOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import config from '../config';

function Header() {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const divRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsDivVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      try {
        const response = await fetch(`${config.API_BASE_URL}/logout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });

        if (response.ok) {
          sessionStorage.removeItem("token");
          navigate("/login");
        } else {
          console.error("Failed to logout");
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }
  };

  return (
    <div className="flex  shadow-xl bg-pink-100 w-full fixed top-0 z-10">
      <div className="header-buttons mx-2 my-2">
        <button
          className="bg-custom-pink border rounded-full d-flex float-start p-3 justify-start"
          onClick={() => setIsDivVisible(!isDivVisible)}
        >
          <HiMiniBars3CenterLeft className="text-lg text-white" />
        </button>

        <div className="flex">
          <img src="assets\images\logo.png" alt="" width="200px" className="bg-custom-pink border rounded-xl ms-14" />
        </div>

      </div>


      {isDivVisible && (
        <div className="bg-custom-pink border rounded-t-2xl p-3 slide-up popup" ref={divRef}>
          <div className="mb-2 mt-4 flex justify-between items-center">
            <img src="assets/images/logo.png" alt="Logo" width="250px" />
            <button onClick={() => setIsDivVisible(false)}>
              <RxCross1 className="text-2xl text-white mb-14" />
            </button>
          </div>
          <hr />

          <div className="mb-2 mt-4 flex flex-col">
            <Link to="/weddingdetails">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <CgNotes className="text-xl text-white" />
                  <h2 className="text-lg text-white ms-2 font-semibold">
                    My Wedding Details
                  </h2>
                </div>
                <IoIosArrowForward className="text-xl text-white" />
              </div>
            </Link>

            <p className="mt-2 ms-6 text-white">
              Dates, Requirements, Events & Other Settings
            </p>
          </div>
          <hr />
          <div className="mb-2 mt-4 flex flex-col">
            <Link to="/myvendors">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <FiShoppingBag className="text-xl text-white" />
                  <h2 className="text-lg text-white ms-2 font-semibold">
                    My Vendors
                  </h2>
                </div>
                <IoIosArrowForward className="text-xl text-white" />
              </div>
            </Link>
          </div>
          <hr />
          <div className="mb-2 mt-4 flex flex-col" onClick={handleLogout}>
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <IoLogOutOutline className="text-xl text-white" />
                <h2 className="text-lg text-white ms-2 font-semibold">
                  Logout
                </h2>
              </div>
              <IoIosArrowForward className="text-xl text-white" />
            </div>
          </div>
          <hr />

          <div>
            <div className="flex justify-between items-center">
              <Link to="/policy">
                <p className="text-lg text-white my-3 font-semibold ms-5">
                  Privacy Policy
                </p>
              </Link>
              <p className="text-lg text-white my-3">|</p>
              <Link to="/t&c">
                <p className="text-lg text-white my-3 font-semibold me-5">
                  Terms & Conditions
                </p>
              </Link>
            </div>
            <div>
              <p className="text-white text-center text-xl my-3">v 1.0.7</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
