import { Link, useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos, MdKeyboardArrowDown } from "react-icons/md";
import React, { useState, useEffect, useRef } from 'react';
import cities from '../cities.json';
import { IoLocationOutline } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { scrollToTop } from "../utils/scrollToTop";
import axios from "axios";
import config from '../config';

function Packages() {
  const navigate = useNavigate();
  // const location = useLocation();
  // const { name } = location.state || { name: "" };

  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })


  const handleClose = () => {
    navigate(-1);
  };

  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCities, setFilteredCities] = useState(cities);
  const [selectedCity, setSelectedCity] = useState('City');
  const [selectedFilters, setSelectedFilters] = useState({
    filter1: null,
    filter2: null,
    filter3: null,
  });

  const cityDropdownRef = useRef(null);
  const filterDropdownRef = useRef(null);

  const toggleCityDropdown = () => setIsCityDropdownOpen(!isCityDropdownOpen);
  const toggleFilterDropdown = () => setIsFilterDropdownOpen(!isFilterDropdownOpen);

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName);
    setIsCityDropdownOpen(false);
  };

  useEffect(() => {
    setFilteredCities(
      cities.filter(city =>
        city.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cityDropdownRef.current && !cityDropdownRef.current.contains(event.target)) {
        setIsCityDropdownOpen(false);
      }
      if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
        setIsFilterDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilterSelect = (heading, option) => {
    setSelectedFilters(prevSelectedFilters => ({
      ...prevSelectedFilters,
      [heading]: option,
    }));
  };

  const handleApplyFilter = () => {
    setIsFilterDropdownOpen(false);
  };

  const filterOptions = {
    filter1: ["Makeup Artist's Studio", "My Venue", "Anywhere is fine",],
    filter2: ["Less then 20,000", "20,000 - 30,000", "30,000 - 50,0000", "Above 50,000"],
    filter3: ["Relvevance", "Price(Low to High)", "Price(High to Low)"],
  };

  const selectedFilterCount = Object.values(selectedFilters).filter(Boolean).length;

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.get(`${config.API_BASE_URL}/packages`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPackages(response.data.response);
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };
    fetchPackages();
  }, []);

  const handleBusinessClick = (businessId) => {
    sessionStorage.setItem('business_id', businessId);
  };

  return (
    <>
      <section ref={topele} >
        <div className="flex flex-col  justify-between shadow-xl bg-pink-100 w-full fixed top-0 z-10">
          <div className="flex items-center">
            <div className="flex items-center">
              <button onClick={handleClose} className="bg-custom-pink border m-2 p-2 rounded-full">
                <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
              </button>
            </div>
            <div className="flex items-center">
              <h2 className="ms-5 text-lg text-semibold font-serif">Packages</h2>
            </div>
          </div>
        </div>
        {/* filter */}
        <div className="mt-20 z-0">
          <div className="flex items-center justify-end">
            <div className="relative me-1" ref={cityDropdownRef}>
              <button onClick={toggleCityDropdown} className="bg-custom-pink px-2 py-1.5 text-sm border rounded-3xl font-serif flex items-center text-white">
                {selectedCity} <MdKeyboardArrowDown className="ml-1" />
              </button>
              {isCityDropdownOpen && (
                <div className="absolute right-0 top-full mt-2 w-64 bg-pink-100 border rounded-lg shadow-lg z-50">
                  <input type="text" value={searchTerm} onChange={handleSearch} className="w-full p-2 border-b" placeholder="Search city..." />
                  <ul className="max-h-60 overflow-y-auto">
                    {filteredCities.map((city, index) => (
                      <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onClick={() => handleCitySelect(city.name)}>
                        {city.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="relative me-3" ref={filterDropdownRef}>
              <button onClick={toggleFilterDropdown} className="bg-custom-pink px-2 py-1.5 text-sm border rounded-3xl font-serif flex items-center text-white">
                Filter {selectedFilterCount > 0 && `(${selectedFilterCount})`} <MdKeyboardArrowDown className="ml-1" />
              </button>
              {isFilterDropdownOpen && (
                <div className="absolute right-0 top-full mt-2 w-64 bg-pink-100 border rounded-lg shadow-lg z-50">
                  <div className="p-2 border-b font-semibold">Filters</div>
                  <div className="p-2 border-b">
                    <h3 className="font-semibold">Makeup to be done at</h3>
                    <ul className="max-h-60 overflow-y-auto">
                      {filterOptions.filter1.map((option, index) => (
                        <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between" onClick={() => handleFilterSelect('filter1', option)}>
                          {option}
                          {selectedFilters.filter1 === option && <span>&#10003;</span>}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="p-2 border-b">
                    <h3 className="font-semibold">Makeup Budget</h3>
                    <ul className="max-h-60 overflow-y-auto">
                      {filterOptions.filter2.map((option, index) => (
                        <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between" onClick={() => handleFilterSelect('filter2', option)}>
                          {option}
                          {selectedFilters.filter2 === option && <span>&#10003;</span>}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="p-2 border-b">
                    <h3 className="font-semibold">Sort Vendors by</h3>
                    <ul className="max-h-60 overflow-y-auto">
                      {filterOptions.filter3.map((option, index) => (
                        <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between" onClick={() => handleFilterSelect('filter3', option)}>
                          {option}
                          {selectedFilters.filter3 === option && <span>&#10003;</span>}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="p-2 border-t">
                    <button onClick={handleApplyFilter} className="bg-custom-pink p-2 border rounded-3xl font-serif text-white w-full">
                      Apply Filters
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* categories */}
        {packages.map(packages => (
          <div className="mt-3 w-full" key={packages.id}>
            <div className="overflow-hidden rounded-lg bg-pink-100">
              <div className="rounded-md border mx-2">
                <img src={`https://bandbaajabaarati.com/vendorbusiness/banner_image/${packages.banner_image}`} alt="Laptop" className="rounded-md object-cover w-full" />

                <div className="p-4">
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <h1 className="font-semibold me-32 text-2xl">{packages.title}</h1>
                    </div>
                    <div className="flex items-center">
                      <FaStar className="mr-1 text-yellow-400" />
                      <p className="text-sm  text-gray-600">4.9(100)</p>
                    </div>
                  </div>

                  <div className="flex items-center text-xl text-gray-600">
                    <IoLocationOutline className="mr-1" />
                    <p>{packages.location}</p>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <MdCurrencyRupee className="mr-1 text-custom-pink text-xl" />
                    <p className="text-custom-pink text-xl">19,499</p>
                  </div>
                  <p className="mt-1 text-base text-gray-600 line-clamp-3">{packages.about}</p>
                </div>
                <div className="p-4 border-t w-full">
                  <Link to="/form"><button className="bg-custom-pink text-white px-20 py-2 text-xl border rounded-lg w-full" onClick={() => handleBusinessClick(packages.id)}>Request Quote</button></Link>
                </div>
              </div>
            </div>
          </div>
        ))}

      </section>
    </>
  );
}

export default Packages;
