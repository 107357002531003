import { CiSearch } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import React, { useState, useEffect, useRef } from 'react';
import cities from '../cities.json';
import { FaStar } from "react-icons/fa";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import axios from "axios";
import config from '../config';


function Vandors() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCities, setFilteredCities] = useState(cities);
  const [selectedCity, setSelectedCity] = useState('Choose City');
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })


  useEffect(() => {
    setFilteredCities(
      cities.filter(city =>
        city.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName);
    setIsOpen(false);
  };



  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem('token');
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${config.API_BASE_URL}/vendors`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data.status === 'success') {
          setVendors(response.data.vendors);
        } else {
          setError('Failed to fetch data');
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p className="text-center mt-10">Loading...</p>;
  if (error) return <p className="text-center mt-10">{error}</p>;

  const handleVendorClick = (vendorId) => {
    sessionStorage.setItem('vendor_id', vendorId);
  };


  return (
    < >
      <Header ref={topele} />

      <h1 className="text-2xl font-serif font-semibold mt-20 mb-3 ms-3" >Vendors</h1>
      <div className="relative">
        <div className="flex justify-between my-6">
          <div className="ms-4">
            <h4 className="text-base font-semibold ">All Wedding Vendors</h4>
          </div>
          <div className="me-4 relative">
            <button onClick={toggleDropdown} className="bg-pink-100 border-none text-sm font-serif flex items-center text-custom-pink">
              {selectedCity} <MdKeyboardArrowDown className="ml-1" />
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-pink-100 border rounded-lg shadow-lg z-50">
                <input type="text" value={searchTerm} onChange={handleSearch} className="w-full p-2 border-b" placeholder="Search city..." />
                <ul className="max-h-60 overflow-y-auto">
                  {filteredCities.map((city, index) => (
                    <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer" onClick={() => handleCitySelect(city.name)}>
                      {city.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* gallery  */}
      <div className="gallery flex flex-wrap ">
        {vendors.map((vendor) => (
          <div className="items-center mt-4" key={vendor.id} onClick={() => handleVendorClick(vendor.id)}>
            <div className="w-1/1 p-2">
              <Link to='/categories' state={{ name: vendor.title }}>
                <img
                  src={`https://bandbaajabaarati.com/category_image/${vendor.image}`}
                  className="rounded-full border-custom-pink border-[2px]"
                  style={{ width: "100px", height: "100px" }}
                  alt=""
                />
              </Link>
              <p className="text-center text-sm text-slate-900 font-semibold mt-2">{vendor.title}</p>
            </div>
          </div>
        ))}
      </div>


      {/* Reviews */}
      <h1 className="text-2xl font-semibold  font-serif mt-12 mb-3 ms-3">Reviews</h1>


      <div className="flex flex-col bg-pink-100 m-auto p-auto">

        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-10">
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/img2.jpg" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/img2.jpg" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/img2.jpg" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/img2.jpg" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/img2.jpg" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
}

export default Vandors;
