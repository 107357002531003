
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BsBookmark } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";
import React, { useEffect, useRef, useState } from 'react';
import { scrollToTop } from "../utils/scrollToTop";
import axios from "axios";
import config from '../config';


function Seeprofile() {

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const [showAll, setShowAll] = useState(false);
  const [filter, setFilter] = useState('All');
  const [activeAccordion, setActiveAccordion] = useState(null);
  const topele = useRef(null);
  const [data, setData] = useState([]);
  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleFilter = (filter) => {
    setFilter(filter);
    setShowAll(true);
  };


  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const pricingRef = useRef(null);

  const handleScrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const photosRef = useRef(null);

  const handleScrollToPhotos = () => {
    if (photosRef.current) {
      photosRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const images = [
    { src: 'assets\\images\\img6.jpg', category: 'Top-Photos' },
    { src: 'assets\\images\\img10.jpg', category: 'Top-Photos' },
    { src: 'assets\\images\\img11.jpg', category: 'Top-Photos' },
    { src: 'assets\\images\\img22.jpg', category: 'Top-Photos' },
    { src: 'assets\\images\\img7.jpg', category: 'JhumpaxDiptarka' },
    { src: 'assets\\images\\img12.jpg', category: 'JhumpaxDiptarka' },
    { src: 'assets\\images\\img13.jpg', category: 'JhumpaxDiptarka' },
    { src: 'assets\\images\\img23.jpg', category: 'JhumpaxDiptarka' },
    { src: 'assets\\images\\img8.jpg', category: 'Wedding' },
    { src: 'assets\\images\\img14.jpg', category: 'Wedding' },
    { src: 'assets\\images\\img15.jpg', category: 'Wedding' },
    { src: 'assets\\images\\img24.jpg', category: 'Wedding' },
    { src: 'assets\\images\\img9.jpg', category: 'Prewedding' },
    { src: 'assets\\images\\img16.jpg', category: 'Prewedding' },
    { src: 'assets\\images\\img17.jpg', category: 'Prewedding' },
    { src: 'assets\\images\\img18.jpg', category: 'Prewedding' },
  ];

  const filteredImages = filter === 'All' ? images : images.filter(image => image.category === filter);

  const [vendorData, setVendorData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await axios.post(
          `${config.API_BASE_URL}/vendors-category`,
          { id: 5 }, // Payload for the POST request if required
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json' // Ensure proper content type
            }
          }
        );
        let id = sessionStorage.getItem('photographer_id');
        setVendorData(response.data);
        let temp = response.data.response.filter((e) => e.id == id);
        setData(temp);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchVendorData();
  }, []);

  const handleBusinessClick = (businessId) => {
    sessionStorage.setItem('business_id', businessId);
  };

  if (error) {
    return <div className="mt-20 text-center">Error: {error}</div>;
  }

  if (!vendorData) {
    return <div className="mt-20 text-center">Loading...</div>;
  }

  // console.log(data)

  return (
    <>
      <div className="flex items-center fixed top-0 z-20 shadow-xl bg-pink-100 w-full" >
        <div className="flex-shrink-0">
          <button className="bg-custom-pink border m-2 p-2.5 rounded-full" onClick={handleClose}>
            <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
          </button>
        </div>
      </div>


      {data.map((data) => (
        <div key={data.id}>
          <div className="relative h-64 w-full mt-16 rounded-xl">

            <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url("https://bandbaajabaarati.com/vendorbusiness/banner_image/${data.banner_image}")` }}
            ></div>


            <div className="absolute inset-0 bg-black opacity-50"></div>


            <div className="absolute mt-4 me-3 right-2 z-10">
              <BsBookmark className=" text-white text-2xl" />
            </div>


            <div className="absolute bottom-2 right-2 z-10" onClick={handleScrollToPhotos}>
              <button className="bg-pink-100 text-slate-800 text-sm py-1 px-1 mb-4 me-3 rounded-3xl flex items-center">
                <IoIosArrowDown className="mr-1" />78 Photos
              </button>
            </div>
          </div>

          <div>
            <div className="flex justify-between items-center ">
              <div className="flex items-center">
                <p className="text-2xl ms-2 font-semibold font-serif mt-2">{data.title}</p>
              </div>
              <div className="flex items-center bg-green-500 border rounded-xl px-1 me-2">
                <FaStar className="mr-1 text-sm text-white" />
                <p className="text-sm text-white me-1">4.9(100)</p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-gray-900 text-lg ms-2">Wedding Photographer in {data.location}</p>
            </div>
          </div>

          < div className=" rounded-lg pb-4" onClick={handleScrollToPricing}>
            <div className="flex justify-between items-center ">
              <div className="flex items-center ms-2">
                <MdCurrencyRupee className=" text-custom-pink text-2xl font-semibold " />
                <p className="text-custom-pink text-3xl font-semibold"> 19,499</p>
              </div>
              <div className="flex">
                <IoIosArrowDown className="mr-4 text-2xl text-slate-500" />

              </div>
            </div>
            <div >
              <p className="text-slate-900 text-base ms-2">For 1 day Photo + Video(See full price list)</p>
            </div>
          </div >

          <div className="mx-2">
            <Link to="/form"><button className="bg-custom-pink text-white p-2 text-xl border w-full rounded-3xl" onClick={() => handleBusinessClick(data.id)}> Request Quote</button></Link>
          </div>

          {/* <div className="mb-3">
            <div className="flex justify-between items-center ">


            </div>
          </div> */}
        </div>
      ))}
      {/* profile */}
      <h1 className="text-2xl font-serif  font-semibold mt-3 ms-3 mb-3" ref={photosRef} > Photo Albums(3)</h1 >

      <div className="flex items-center justify-center py-4 md:py-8 flex-wrap " >
        <button
          type="button"
          onClick={() => handleFilter('All')}
          className="text-gray-900 border border-gray-200 dark:border-gray-900 dark:bg-gray-900 bg-pink-100 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 font-serif"
        >
          Top-Photos
        </button>
        <button
          type="button"
          onClick={() => handleFilter('JhumpaxDiptarka')}
          className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-pink-100 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 font-serif"
        >
          Jhumpa x Diptarka
        </button>
        <button
          type="button"
          onClick={() => handleFilter('Wedding')}
          className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-pink-100 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 font-serif"
        >
          Wedding
        </button>
        <button
          type="button"
          onClick={() => handleFilter('Prewedding')}
          className="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-pink-100 focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800 font-serif"
        >
          Pre Wedding
        </button>
      </div>
      <div className="grid grid-cols-3 md:grid-cols-3">
        {filteredImages.slice(0, showAll ? filteredImages.length : 6).map((image, index) => (
          <div key={index}>
            <img className="h-52 max-w-22 rounded-lg mt-1" src={image.src} alt="" />
          </div>
        ))}

      </div>
      {
        !showAll && filteredImages.length > 6 && (
          <div className="flex justify-end rounded-lg mt-1 cursor-pointer" onClick={handleShowMore}>
            <button className="bg-pink-100 text-slate-800 text-base py-3 px-3 mb-4 me-3 rounded-3xl flex items-center">
              See more <IoIosArrowDown className="ml-1" />
            </button>
          </div>
        )
      }

      {/* pricing */}
      <h1 className="text-2xl font-serif  font-semibold  ms-3 mb-3 mt-2" ref={pricingRef}>Pricing</h1>
      <div id="accordion-collapse" data-accordion="collapse">
        {/* Accordion Item 1 */}
        <div>
          <h2 id="accordion-collapse-heading-1">
            <button
              type="button"
              className="flex justify-between items-center w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() => toggleAccordion(1)}
              aria-expanded={activeAccordion === 1}
              aria-controls="accordion-collapse-body-1"
            >
              <div className="flex flex-col">
                <p className="text-sm text-black">CANDID PHOTOGRAPHY</p>
                <div className="flex items-center">
                  <MdCurrencyRupee className="text-black text-sm font-semibold" />
                  <p className="text-black text-sm font-semibold">19,499 per day</p>
                </div>
              </div>
              <IoIosArrowDown className="ml-4 text-base text-black" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-1"
            className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${activeAccordion === 1 ? '' : 'hidden'}`}
            aria-labelledby="accordion-collapse-heading-1"
          >
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons,
              dropdowns, modals, navbars, and more.
            </p>
          </div>
        </div>

        {/* Accordion Item 2 */}
        <div>
          <h2 id="accordion-collapse-heading-2">
            <button
              type="button"
              className="flex justify-between items-center w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() => toggleAccordion(2)}
              aria-expanded={activeAccordion === 2}
              aria-controls="accordion-collapse-body-2"
            >
              <div className="flex flex-col">
                <p className="text-sm text-black">TRADITIONAL PHOTOGRAPHY</p>
                <div className="flex items-center">
                  <MdCurrencyRupee className="text-black text-sm font-semibold" />
                  <p className="text-black text-sm font-semibold">10,000 per day</p>
                </div>
              </div>
              <IoIosArrowDown className="ml-4 text-base text-black" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-2"
            className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${activeAccordion === 2 ? '' : 'hidden'}`}
            aria-labelledby="accordion-collapse-heading-2"
          >
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons,
              dropdowns, modals, navbars, and more.
            </p>
          </div>
        </div>

        {/* Accordion Item 3 */}
        <div>
          <h2 id="accordion-collapse-heading-3">
            <button
              type="button"
              className="flex justify-between items-center w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() => toggleAccordion(3)}
              aria-expanded={activeAccordion === 3}
              aria-controls="accordion-collapse-body-3"
            >
              <div className="flex flex-col">
                <p className="text-sm text-black">CINEMATIC VIDEOGRAPHY</p>
                <div className="flex items-center">
                  <MdCurrencyRupee className="text-black text-sm font-semibold" />
                  <p className="text-black text-sm font-semibold">10,000 per day</p>
                </div>
              </div>
              <IoIosArrowDown className="ml-4 text-base text-black" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-3"
            className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${activeAccordion === 3 ? '' : 'hidden'}`}
            aria-labelledby="accordion-collapse-heading-3"
          >
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons,
              dropdowns, modals, navbars, and more.
            </p>
          </div>
        </div>

        {/* Accordion Item 4 */}
        <div>
          <h2 id="accordion-collapse-heading-4">
            <button
              type="button"
              className="flex justify-between items-center w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() => toggleAccordion(4)}
              aria-expanded={activeAccordion === 4}
              aria-controls="accordion-collapse-body-4"
            >
              <div className="flex flex-col">
                <p className="text-sm text-black">TRADITIONAL VIDEOGRAPHY</p>
                <div className="flex items-center">
                  <MdCurrencyRupee className="text-black text-sm font-semibold" />
                  <p className="text-black text-sm font-semibold">10,000 per day</p>
                </div>
              </div>
              <IoIosArrowDown className="ml-4 text-base text-black" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-4"
            className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${activeAccordion === 4 ? '' : 'hidden'}`}
            aria-labelledby="accordion-collapse-heading-4"
          >
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons,
              dropdowns, modals, navbars, and more.
            </p>
          </div>
        </div>

        {/* Accordion Item 5 */}
        <div>
          <h2 id="accordion-collapse-heading-5">
            <button
              type="button"
              className="flex justify-between items-center w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
              onClick={() => toggleAccordion(5)}
              aria-expanded={activeAccordion === 5}
              aria-controls="accordion-collapse-body-5"
            >
              <div className="flex flex-col">
                <p className="text-sm text-black">PHOTO ALBUM</p>
                <div className="flex items-center">
                  <MdCurrencyRupee className="text-black text-sm font-semibold" />
                  <p className="text-black text-sm font-semibold">10,000 (40 pages)</p>
                </div>
              </div>
              <IoIosArrowDown className="ml-4 text-base text-black" />
            </button>
          </h2>
          <div
            id="accordion-collapse-body-5"
            className={`p-5 border border-b-0 border-gray-200 dark:border-gray-700 ${activeAccordion === 5 ? '' : 'hidden'}`}
            aria-labelledby="accordion-collapse-heading-5"
          >
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Flowbite is an open-source library of interactive components built on top of Tailwind CSS including buttons,
              dropdowns, modals, navbars, and more.
            </p>
          </div>
        </div>
      </div>
      <hr />
      {/* policy */}
      <div>
        <div>
          <h3 className="text-sm text-slate-500 ms-3 mt-2 font-semibold">PAYMENT POLICY</h3>
          <p className="text-sm text-black font-semibold mt-1 ms-3">20% - At the Time of booking</p>
          <p className="text-sm text-black font-semibold mt-1 ms-3">60% - On Event date</p>
          <p className="text-sm text-black font-semibold mt-1 ms-3">20% - After deliverables are refundable</p>
        </div>
        <div className="mt-5 mb-2">
          <h3 className="text-sm text-slate-500 ms-3 mt-2 font-semibold">CANCELLATION POLICY</h3>
          <p className="text-sm text-black font-semibold mt-1 ms-3">Advance amount of 20% will be non refundable</p>
        </div>
        <hr />
        <div className="mb-5">
          <h3 className="text-base text-black ms-3 mt-2 font-semibold">About the undefined</h3>
          <h3 className="text-sm text-slate-500 ms-3 mt-2 font-semibold">INTRODUCTION</h3>
          <p className="text-sm text-slate-600  mt-1 ms-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, totam voluptate. Porro itaque et fugit numquam dignissimos excepturi sed. Dolore atque delectus beatae adipisci illo quo! Voluptatum eius incidunt dolorum et quam maiores, quas officia magnam minima aliquam eos Lorem ipsum dolor sit amet. illum.</p>

          <p className="text-sm text-slate-600  mt-1 ms-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, totam voluptate. Porro itaque et fugit numquam dignissimos excepturi sed. Dolore atque delectus beatae adipisci illo quo! Voluptatum eius incidunt dolorum et quam maiores, quas officia magnam minima aliquam eos illum.</p>
        </div>
        <hr />
        <div className="my-5">
          <h3 className="text-sm text-slate-500 ms-3 mt-2 font-semibold">WORKING SINCE </h3>
          <p className="text-sm text-black font-semibold mt-1 ms-3">2019</p>
        </div>

        <hr />
        <div className="my-3">
          <h3 className="text-sm text-slate-500 ms-3 mt-2 font-semibold">TRAVEL POLICY</h3>
          <p className="text-sm text-black font-semibold mt-1 ms-3">Travels to other cities</p>
        </div>
        <hr />
      </div>

      {/* review */}
      <div className="my-5">
        <h1 className="text-2xl font-serif  font-semibold  ms-3 mb-3">Review (100)</h1>
        <div className="flex items-center">
          <p className="text-5xl font-semibold mb-4 ms-3 me-5">4.9</p>
          <FaStar className="mr-1 text-green-500 text-lg" />
          <FaStar className="mr-1 text-green-500 text-lg" />
          <FaStar className="mr-1 text-green-500 text-lg" />
          <FaStar className="mr-1 text-green-500 text-lg" />
          <FaStar className="mr-1 text-green-500 text-lg" />
        </div>

        <article className="mx-3 my-3">
          <div className="flex justify-between items-center ">
            <div className="flex items-center">
              <img className="w-10 h-10 me-4 rounded-full" src="assets\images\default.png" alt="" />
              <p>Jese Leos <time dateTime="2014-08-16 19:00" className="block text-sm text-gray-500 dark:text-gray-400">Joined on August 2014</time></p>
            </div>
            <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
              <FaStar className="mr-1 text-white" />
              <p className="text-base text-white me-1">4.9</p>
            </div>
          </div>
          <footer className="mb-5 mt-3 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed in the United Kingdom on <time dateTime="2017-03-03 19:00">March 3, 2017</time></p></footer>
          <p className="mb-2 text-gray-500 dark:text-gray-400">This is my third Invicta Pro Diver. They are just fantastic value for money. This one arrived yesterday and the first thing I did was set the time, popped on an identical strap from another Invicta and went in the shower with it to test the waterproofing.... No problems.</p>
          <Link to="#" className="block mb-5 text-base text-custom-pink hover:underline dark:text-custom-pink">Read more</Link>
        </article>
        <hr />
        <article className="mx-3 my-3">
          <div className="flex justify-between items-center ">
            <div className="flex items-center">
              <img className="w-10 h-10 me-4 rounded-full" src="assets\images\default.png" alt="" />
              <p>Jese Leos <time dateTime="2014-08-16 19:00" className="block text-sm text-gray-500 dark:text-gray-400">Joined on August 2014</time></p>
            </div>
            <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
              <FaStar className="mr-1 text-white" />
              <p className="text-base text-white me-1">4.9</p>
            </div>
          </div>
          <footer className="mb-5 mt-3 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed in the United Kingdom on <time dateTime="2017-03-03 19:00">March 3, 2017</time></p></footer>
          <p className="mb-2 text-gray-500 dark:text-gray-400">This is my third Invicta Pro Diver. They are just fantastic value for money. This one arrived yesterday and the first thing I did was set the time, popped on an identical strap from another Invicta and went in the shower with it to test the waterproofing.... No problems.</p>
          <Link to="#" className="block mb-5 text-base text-custom-pink hover:underline dark:text-custom-pink">Read more</Link>
        </article>
        <hr />
        <article className="mx-3 my-3">
          <div className="flex justify-between items-center ">
            <div className="flex items-center">
              <img className="w-10 h-10 me-4 rounded-full" src="assets\images\default.png" alt="" />
              <p>Jese Leos <time dateTime="2014-08-16 19:00" className="block text-sm text-gray-500 dark:text-gray-400">Joined on August 2014</time></p>
            </div>
            <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
              <FaStar className="mr-1 text-white" />
              <p className="text-base text-white me-1">4.9</p>
            </div>
          </div>
          <footer className="mb-5 mt-3 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed in the United Kingdom on <time dateTime="2017-03-03 19:00">March 3, 2017</time></p></footer>
          <p className="mb-2 text-gray-500 dark:text-gray-400">This is my third Invicta Pro Diver. They are just fantastic value for money. This one arrived yesterday and the first thing I did was set the time, popped on an identical strap from another Invicta and went in the shower with it to test the waterproofing.... No problems.</p>
          <Link to="#" className="block mb-5 text-base text-custom-pink hover:underline dark:text-custom-pink">Read more</Link>
        </article>
        <hr />
        <article className="mx-3 my-3">
          <div className="flex justify-between items-center ">
            <div className="flex items-center">
              <img className="w-10 h-10 me-4 rounded-full" src="assets\images\default.png" alt="" />
              <p>Jese Leos <time dateTime="2014-08-16 19:00" className="block text-sm text-gray-500 dark:text-gray-400">Joined on August 2014</time></p>
            </div>
            <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
              <FaStar className="mr-1 text-white" />
              <p className="text-base text-white me-1">4.9</p>
            </div>
          </div>
          <footer className="mb-5 mt-3 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed in the United Kingdom on <time dateTime="2017-03-03 19:00">March 3, 2017</time></p></footer>
          <p className="mb-2 text-gray-500 dark:text-gray-400">This is my third Invicta Pro Diver. They are just fantastic value for money. This one arrived yesterday and the first thing I did was set the time, popped on an identical strap from another Invicta and went in the shower with it to test the waterproofing.... No problems.</p>
          <Link to="#" className="block mb-5 text-base text-custom-pink hover:underline dark:text-custom-pink">Read more</Link>
        </article>
        <hr />

      </div>


    </>
  );
}

export default Seeprofile;