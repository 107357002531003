
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Login from "../pages/Login";
import Getstart from "../pages/Getstart";
import Otp from "../pages/Otp";
import Home from "../pages/Home";
import Vandors from "../pages/Vendors";
import Seeprofile from "../pages/Seeprofile";
import Form from "../components/Form";
import Categories from "../pages/Categories";
import Blog from "../pages/Blog";
import Search from "../components/Search";
import Policy from "../pages/Policy";
import Termsandcondition from "../pages/Termsandcondition";
import Contact from "../pages/Contact";
import Profile from "../pages/Profile";
import Aboutus from "../pages/Aboutus";
import Packages from "../pages/Package";
import MyVendors from "../pages/Myvendors";
import Weddingdetails from "../pages/Weddingdetails";


export default function Routess() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Getstart />} />
        <Route path='/login' element={<Login />} />
        <Route path='/otp' element={<Otp />} />
        <Route path='/home' element={<Home />} />
        <Route path='/vandors' element={<Vandors />} />
        <Route path='/seeprofile' element={<Seeprofile />} />
        <Route path='/form' element={<Form />} />
        <Route path='/categories' element={<Categories />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/search' element={<Search />} />
        <Route path='/policy' element={<Policy />} />
        <Route path='/t&c' element={<Termsandcondition />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/aboutus' element={<Aboutus />} />
        <Route path='/packages' element={<Packages />} />
        <Route path='/myvendors' element={<MyVendors />} />
        <Route path='/weddingdetails' element={<Weddingdetails />} />
      </Routes>
    </Router>
  )
}
