import React, { useState, useEffect } from 'react';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';

function Otp() {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(60);
  const [canResendOtp, setCanResendOtp] = useState(false);
  const navigate = useNavigate();
  const mobileNumber = sessionStorage.getItem('mobileNumber');

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResendOtp(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleOtpChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setOtp(value);
    }
  };

  const handleResendOtp = () => {
    setTimer(60);
    setCanResendOtp(false);
    toast.info('OTP resent successfully');
    // Add logic for resending OTP here if needed
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${config.API_BASE_URL}/register`, {
        phone_number: mobileNumber,
        otp: otp
      });
      // console.log(response)
      if (response.data && response.data.token) {
        sessionStorage.setItem('token', response.data.token);
        toast.success('OTP verified successfully');
        navigate('/home');
      } else {
        toast.error('Error verifying OTP');
      }
    } catch (error) {
      toast.error('Error verifying OTP');
      console.error('Error verifying OTP:', error);
    }
  };

  const token = sessionStorage.getItem('token');

  useEffect(() => {
    if (token) {
      navigate("/home")
    }
  }, [token])


  return (
    <div className="bg-custom-pink min-h-screen flex flex-col justify-between">
      <ToastContainer autoClose={2000} />
      <div className="mb-2 flex justify-center mt-12">
        <img src="assets/images/logo1.png" alt="Logo" width="150px" />
      </div>
      <div className='mb-10'>
        <div className='flex justify-center'>
          <img src="assets/images/logo2.png" alt="Logo" width="300px" />
        </div>
        <div>
          <p className='text-white text-center text-sm font-serif font-semibold'>India 's Largest wedding company</p>
        </div>
      </div>
      <div className="bg-white border rounded-t-2xl p-6">
        <Link to="/login">
          <IoArrowBackCircleOutline className='text-5xl mb-3 text-gray-600' />
        </Link>
        <h1 className="text-black text-3xl font-serif font-bold">Enter OTP</h1>
        <p className="text-gray-400 mt-3 mb-2">4-digit OTP has been sent to {mobileNumber}</p>

        <form onSubmit={handleSubmit}>
          <div className="w-full xl:w-1/3 mb-4 mx-auto">
            <input
              className="flex h-12 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-xl placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1"
              type="number"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOtpChange}
              maxLength="4"
              required
              autoFocus
            />

            <div className="text-gray-600 mb-4 flex justify-between">
              <span>
                {timer > 0 ? `Resend OTP in ${timer} seconds` : 'You can now resend the OTP'}
              </span>
              {canResendOtp && (<p className="ml-2 cursor-pointer" onClick={handleResendOtp}>Resend</p>)}
            </div>
          </div>

          <div className="w-full xl:w-1/3 mx-auto">
            <button
              className={`bg-custom-pink border rounded-full p-3 d-flex float-end ${otp.length !== 4 ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={otp.length !== 4}
              type="submit"
            >
              <IoIosArrowForward className='text-2xl text-white ' />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Otp;
