import { Link, useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { PiShareFatLight } from "react-icons/pi";
import { IoCheckmarkOutline } from "react-icons/io5";
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import { PiUserCircleCheck } from "react-icons/pi";
import { FcIdea } from "react-icons/fc";
import { GiLightBulb } from "react-icons/gi";
import { FaRegHeart } from "react-icons/fa";



function Aboutus() {
  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })


  const handleClose = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="shadow-xl bg-pink-100 w-full fixed top-0 z-10" ref={topele}>
        <div className="flex  items-center ">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
          <div className="flex items-center  ">

          </div>
        </div>
      </div>

      <div className="relative w-full h-64 overflow-hidden mt-16">

        <img src="assets\images\img5.jpg" alt="" className="w-full h-full" />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50  items-center justify-center">
          <h1 className="text-white text-center text-xl md:text-6xl lg:text-8xl  mt-20">We are Band Baja Baraati </h1>
          <h1 className="text-white text-center text-xl md:text-6xl lg:text-8xl font-bold">We are bringing dream weddings to life!</h1>

        </div>
      </div>
      <h2 className="mt-3 font-bold text-2xl font-serif mx-3 text-center">About Us </h2>
      <div>
        <p className="mt-8 mx-3 text-justify">Band Baja Baraati is an Indian online wedding planning platform and a wedding media publisher, enabling couples to plan their weddings in a convenient & cost-effective manner.
        </p>
        <p className="mt-8 mx-3 text-justify">BandBajaBaarati is on a spree to make wedding planning in India exciting and hassle-free. With a millennial army of wedding fanatics, BandBajaBaarati aims to aid wedding blues of every new-age couple across the country.
        </p>
        <p className="mt-8 mx-3 text-justify">
          We’re a driven team of wedding enthusiasts working to build a new way of wedding planning through delightful products and amazing customer service. We’re proud to have been the official wedding planner of celebrities like Yuvraj Singh & Bhuvneshwar Kumar. We love what we do, and that's how we help plan your wedding like a loved one!
        </p>

        <div className="bg-pink-100 py-5 mt-5">
          <h2 className="mt-8  font-bold text-center text-5xl font-serif mx-8">60,000</h2>
          <p className=" text-gray-500 mt-3 text-center text-lg" >Wedding Vendors</p>
          <h2 className="mt-10  font-bold text-center text-5xl font-serif mx-8">10,000</h2>
          <p className=" text-gray-500 mt-3 text-center text-lg" >Annual Wedding</p>
          <h2 className="mt-10  font-bold text-center text-5xl font-serif mx-8">2.5M</h2>
          <p className=" text-gray-500 mt-3 text-center text-lg" >Monthly Media Followers</p>
          <h2 className="mt-10  font-bold text-center text-5xl font-serif mx-8">20M</h2>
          <p className=" text-gray-500 mt-3 text-center text-lg" >Monthly Reach</p>
        </div>

        <h2 className="mt-6 text-center font-bold text-2xl font-serif ">What do we offer?</h2>
        <p className="mt-4 mx-3  text-justify">WeddingBazaar offers an end-to-end wedding planning solution for couples to ideate & realize their dream wedding conveniently & reliably. WeddingBazaar is the only wedding planning startup in India that offers assisted wedding planning through personal wedding manager for all your needs of booking the perfect service provider. WeddingBazaar is also an encyclopedia of latest trends, expert opinions and practical advice on wedding planning - from choosing wedding theme, best lehenga designs, mehndi designs, sangeet ideas, photo poses, etc.</p>

        <div className="flex items-center justify-center mt-6">
          <PiUserCircleCheck className="mr-2 text-3xl text-custom-pink" />
          <h2 className="font-bold text-lg text-custom-pink">Trusted Wedding Vendors</h2>
        </div>
        <p className="mt-4 mx-3  text-justify text-gray-600">We totally understand how crucial your wedding is, which is precisely why you’ll only find the most trusted vendors who are verified by our team. Be assured that you’ll have the best vendor in your budget, thanks to our natively built machine-learning based vendor matching algorithm which perfectly matches your requirements, dates & budget to that of thousands of vendors and choose the best options for you!</p>

        <div className="flex items-center justify-center mt-6">
          <GiLightBulb className="mr-2 text-3xl text-custom-pink" />

          <h2 className="font-bold text-lg text-custom-pink">Wedding Ideas, Inspiration & Advice</h2>
        </div>
        <p className="mt-4 mx-3  text-justify text-gray-600">It all starts with discovering the right idea so that you can make your wedding unique and fun! We host a tremendous collection of latest ideas, trends and practical advice - directly sourced from real wedding experiences of brides and vendors. Our fanatical wedding content team is an expert in bringing to you the most helpful and trendy ideas, which is why we’re followed and read by millions of brides!</p>
        <div className="flex justify-center">
          <button className="bg-custom-pink border m-2 p-2.5 text-white font-bold justify-center rounded-full mt-5">
            Explore Wedding Blog
          </button>
        </div>




        <h2 className="mt-6 text-center font-bold text-2xl font-serif text-custom-pink mb-6">What makes us different?</h2>
        <h2 className="font-bold text-lg text-center">For Couples</h2>
        <div className="flex items-center justify-center mx-3 mt-6">
          <FaRegHeart className="mr-2 text-6xl text-custom-pink mb-16" />
          <p>Assisted Wedding Planning - A personal wedding manager to solve all your booking queries & negotiate with vendors to get the best deal for you</p>
        </div>
        <div className="flex items-center justify-center mx-3 mt-6">
          <FaRegHeart className="mr-2 text-5xl text-custom-pink mb-10" />
          <p>
            State-of-art technology - To get the best ideas & perfectly matched vendors as per your requirements & budget</p>
        </div>
        <div className="flex items-center justify-center mx-3 mt-6">
          <FaRegHeart className="mr-2 text-5xl text-custom-pink mb-10" />
          <p>Trust & Reliability in services - Established through verified vendors, backup assurance & dispute resolution</p>
        </div>

        <h2 className="font-bold text-lg text-center mt-6">For Vendor Partners</h2>
        <div className="flex items-center justify-center mx-3 ">
          <PiUserCircleCheck className="mr-2 text-8xl text-custom-pink mb-16" />
          <p>Predictable business - Through enhanced algorithms matching customer demands in various requirements-budget ranges with available vendor supply round the year</p>
        </div>
        <div className="flex items-center justify-center mx-3 ">
          <PiUserCircleCheck className="mr-2 text-9xl text-custom-pink mb-20 " />
          <p>Boost business through technology - CRM tools to helps you focus on the most important customers and improve booking conversions, building a mutually fulfilling relationship with Band Baja Baraati</p>
        </div>
        <div className="flex items-center justify-center mx-3 ">
          <PiUserCircleCheck className="mr-2 text-8xl text-custom-pink mb-16" />
          <p>Focus on creative work - We get done all the unproductive stuff for you, so that you can focus on improving customer’s experience through your creative excellence!</p>
        </div>

        <div className="flex justify-center mb-3">
          <button className="bg-custom-pink border m-2 p-2.5 text-white font-bold justify-center rounded-full mt-5">
            Register as a Vendor
          </button>
        </div>

        <hr className="bg-black" />

        <div className="bg-pink-100 py-5 mt-5">
          <h2 className="mt-8 mb-5  font-bold text-center text-2xl font-serif mx-8">Who we are</h2>
          <p className=" text-gray-900 mt-3 mx-4 text-center text-lg" >We are <span className="text-black font-bold">passionate.</span>we drive <span className="text-black font-bold">change.</span>We move <span className="text-black font-bold">fast.</span>We love to <span className="text-black font-bold">win.</span>We value our <span className="text-black font-bold">team.</span>We are <span className="text-black font-bold">supporative.</span>We are <span className="text-black font-bold">kind.</span> we are <span className="text-black font-bold">optimistic.</span> We are <span className="text-black font-bold">creative.</span>We<span className="text-black font-bold">collaborate.</span>We say <span className="text-black font-bold">cheers.</span></p>
          <p className=" text-gray-900 mt-3 text-center text-lg" >We are <span className="text-black font-bold">BandBajaBaarati.</span></p>
        </div>

        <h4 className="text-2xl mt-16 ms-5 font-semibold mb-2 text-center  font-serif">Follow Us</h4>
        <div className="flex ms-5 mt-8 py-2 justify-center mb-20">
          <Link to="https://www.facebook.com/people/Band-Baaja-Baarati/61563147146010/">
            <img src="assets\images\facebook.png" alt="" width="50px" className="me-2 bg-pink-100 border rounded-full" />
          </Link>
          <Link to="https://www.instagram.com/bandbaajabaaratiofficial/" className="text-white ">
            <img src="assets\images\instagram.png" alt="" width="55px" className="me-2  bg-pink-100 border rounded-full" />
          </Link>
          <Link to="https://in.pinterest.com/bandbajabaraati/" className="text-white ">
            <img src="assets\images\pinterest.png" alt="" width="50px" className="me-2  bg-pink-100 border rounded-full" />
          </Link>

        </div>

      </div>

      <Footer />

    </>
  );
}

export default Aboutus;