import Header from "../components/Header";
import { IoLocationOutline } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { SiMicrodotblog } from "react-icons/si";
import { FaPlaceOfWorship } from "react-icons/fa";
import { BsDropbox } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import config from '../config';
import Banner from "../components/Banner";
import Footer from "../components/Footer";


function Home() {
  const [vendors, setVendors] = useState([]);
  const [blogs, setBlogs] = useState([])
  const [packages, setPackages] = useState(null);


  const fetchBlogs = async () => {
    const token = sessionStorage.getItem('token');
    try {
      const response = await axios.get(`${config.API_BASE_URL}/blogs`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setBlogs(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);


  if (!sessionStorage.getItem('blog_id')) {
    sessionStorage.setItem('blog_id', 2);
  }
  const handleBlogClick = (blogId) => {
    sessionStorage.setItem('blog_id', blogId);
  };

  // console.log(blogs)

  useEffect(() => {
    const fetchVendors = async () => {

      const token = sessionStorage.getItem('token');

      if (token) {
        try {
          const response = await axios.get(`${config.API_BASE_URL}/core-details`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setVendors(response.data.vendors);
          // console.log(response)
        } catch (error) {
          console.error('Error fetching vendors:', error);
        }
      } else {
        console.error('Token not found in sessionStorage');
      }
    };

    fetchVendors();
  }, []);

  const handleVendorClick = (vendorId) => {
    sessionStorage.setItem('vendor_id', vendorId);
  };

  const [photographers, setPhotographers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //  photographers

  useEffect(() => {
    const fetchVendorData = async () => {
      const vendorId = 5;
      const token = sessionStorage.getItem('token');

      if (!vendorId) {
        setError('Vendor ID not found in session storage');
        setLoading(false);
        return;
      }
      if (!token) {
        setError('Token not found in session storage');
        setLoading(false);
        return;
      }
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/vendors-category`,
          { id: vendorId },

          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setPhotographers(response.data.response);
      } catch (err) {
        setError('Error fetching vendor data');
      } finally {
        setLoading(false);
      }
    };

    fetchVendorData();
  }, []);

  const handlePhotographerClick = (photographerId) => {
    sessionStorage.setItem('photographer_id', photographerId);
    sessionStorage.removeItem('package_id');
  };

  if (loading) {
    return <div className="text-center mt-20">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-20">{error}</div>;
  }





  return (

    <>

      <Header />


      <div className="mt-14">
        <Banner />
      </div>

      {/* Browse More Categories */}
      <div className="flex justify-between mx-3">
        <div>
          <h1 className="text-base   font-semibold my-5">Browse Categories</h1>
        </div>
        <div>
          <Link to='/vandors'><p className="text-sm font-bold  mt-5 text-custom-pink">See All</p></Link>
        </div>
      </div>
      <div className="flex flex-col bg-pink-100 m-auto p-auto">
        <div className="flex overflow-x-scroll  hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-6 md:ml-4 ml-2  ">
            {vendors.map((vendor) => (
              <div className="inline-block" key={vendor.id} onClick={() => handleVendorClick(vendor.id)}>
                <div className="w-24 max-w-xs overflow-hidden rounded-full duration-300 ease-in-out">
                  <Link to="/categories" state={{ name: vendor.title }} > <img
                    className="inline-block rounded-full m-2  border-slate-400 border-[1px]"
                    src={`https://bandbaajabaarati.com/category_image/${vendor.image}`}
                    alt=""
                    style={{ width: "70px", height: "70px" }}
                  />
                  </Link>
                </div>
                <p className="text-center text-sm me-2  font-semibold">{vendor.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>



      {/* My Vendors */}
      <h1 className="text-base  font-semibold mt-5 mb-3 ms-3">My Vendors</h1>

      <div className="border-2 shadow-lg rounded-2xl mx-2 border-custom-pink p-4">
        <div className="flex flex-row justify-between">
          <div className="flex-1 mr-2">
            <img
              src="assets/images/img2.jpg"
              alt=""
              width="200px"
              className="border rounded-2xl m-2"
            />
          </div>

          <div className="flex-1 ml-2">
            <div className="flex flex-col items-center">
              <img
                className="h-8 w-8 rounded-full float-start m-2"
                src="assets/images/img2.jpg"
                alt=""
              />
              <p className="text-slate-900 font-bold">
                <span className="text-red-600 font-bold float-start">1</span> Photograph
              </p>
              <p className="text-black float-start">Shortlisted</p>
            </div>
          </div>
        </div>

        <div className="mt-4 px-2 pb-2 flex justify-center w-full">
          <button type="button" className="rounded-md bg-custom-pink px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-custom-pink/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-custom-pink w-full"> See All</button>
        </div>
      </div>




      {/* Photographers For you */}
      <h1 className="text-base  font-semibold my-6  ms-3 mb-3">Photographers</h1>

      <div className="flex flex-col bg-pink-100 m-auto p-auto">
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-6 md:ml-4 ml-2 ">
            {photographers && photographers.map((photographer) => (
              <div className="inline-block pe-3" key={photographer.id}>
                <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <div className="w-[300px] rounded-md border">
                    <img src={`https://bandbaajabaarati.com/vendorbusiness/banner_image/${photographer.banner_image}`} alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                    <div className="p-4">
                      <h1 className="text-xl font-semibold font-serif line-clamp-1">{photographer.title}</h1>
                      <div className="mt-3 flex items-center text-sm text-gray-600">
                        <IoLocationOutline className="mr-1 text-lg" />
                        <p className="text-lg">{photographer.location}</p>
                      </div>
                      <div className="flex items-center text-sm text-gray-600">
                        <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" />
                        <p className="text-custom-pink text-lg">19,499</p>
                      </div>
                      <p className="mt-1 text-sm text-gray-600">For 1 day Photo + Video</p>
                    </div>
                    <div className="flex justify-between items-center p-4 border-t">
                      <div className="flex items-center">
                        <FaStar className="mr-1 text-yellow-400" />
                        <p className="text-sm text-black">4.9(100)</p>
                      </div>
                      <div className="flex items-center" onClick={() => handlePhotographerClick(photographer.id)}>
                        <Link to='/seeprofile' >  <p className="text-sm text-custom-pink">See Profile</p></Link>
                        <IoIosArrowForward className="ml-1 text-custom-pink" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>


      {/* Get Inspired By Amazing Wedding Ideas ! */}
      <h1 className="text-base   font-semibold  ms-3 mb-3">Get Inspired By Amazing Wedding Ideas !</h1>

      <div className="flex flex-col bg-pink-100 m-auto p-auto">
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-6 md:ml-4 ml-2 ">
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block pe-3">
              <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="w-[300px] rounded-md border">
                  <img src="assets/images/img4.jpg" alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                  <div className="p-4">
                    <h1 className="text-sm text-gray-500 my-1">Pratiksha Panday - 2 Jul 2024</h1>
                    <p className="mt-1 text-base text-gray-700">Cocktail Saree With Jackets Are So In & Here Are Some Of Our Favorits That You Can Buy! </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Real Wedding Stories */}
      <h1 className="text-base   font-semibold  ms-3 mb-3">Real Wedding Stories</h1>

      <div className="flex flex-col bg-pink-100 m-auto p-auto mb-10">
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-6 md:ml-4 ml-2 ">

            {blogs.blogs && blogs.blogs.map((blog) => (
              <Link to="/blog" key={blog.id} onClick={() => handleBlogClick(blog.id)}>
                <div className="inline-block pe-3" >
                  <div className=" max-w-xs overflow-hidden rounded-lg shadow-md bg-pink-100 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                    <div className="w-[300px] rounded-md border">
                      <img src={`https://bandbaajabaarati.com/blog_images/${blog.image}`} alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
                      <div className="p-4 overflow-auto h-32" >
                        <h1 className="text-sm text-black font-serif font-bold my-1 line-clamp-2">{blog.title}</h1>
                        <p className="mt-1 text-sm text-gray-700 line-clamp-2" dangerouslySetInnerHTML={{ __html: blog.description }}></p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

          </div>
        </div>
      </div>

      {/* menu */}

      <Link to={`https://wa.me/8440011500`}>
        <div className="fixed bottom-16 mb-2 me-2 right-0 mt-10 bg-pink-100 border rounded-full">
          <img src="assets/images/wapp.png" alt="" width="35px" />
        </div>
      </Link>

      <div className="flex justify-between fixed bottom-0 bg-pink-100 py-2 w-full me-10">
        <Link to="/blog">
          <div className="flex flex-col items-center px-4">
            <SiMicrodotblog className="text-custom-pink text-xl" />
            <p className="text-center text-custom-pink font-semibold text-sm">Blog</p>
          </div>
        </Link>

        <Link to="/packages">
          <div className="flex flex-col items-center px-4">
            <FaPlaceOfWorship className="text-custom-pink text-xl" />
            <p className="text-center text-custom-pink font-semibold text-sm">Packages</p>
          </div>
        </Link>

        <Link to="/vandors">
          <div className="flex flex-col items-center px-4">
            <BsDropbox className="text-custom-pink text-xl" />
            <p className="text-center text-custom-pink font-semibold text-sm">Vendor</p>
          </div>
        </Link>

        <Link to="/profile">
          <div className="flex flex-col items-center px-4">
            <FaRegUserCircle className="text-custom-pink text-xl" />
            <p className="text-center text-custom-pink font-semibold text-sm">Profile</p>
          </div>
        </Link>
      </div>
      <div className="mb-16">
        <Footer />
      </div>

    </>
  );
}

export default Home;