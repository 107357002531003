import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RxCross1 } from 'react-icons/rx';

function Form() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleClose = () => {
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const token = sessionStorage.getItem('token');
    const businessId = sessionStorage.getItem('business_id');
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await axios.post(
        'https://bandbaajabaarati.com/api/send-enquriy',
        { message, business_id: businessId },
        { headers }
      );

      if (response.data.status === 'success') {
        setResponseMessage('Request submitted successfully');
        localStorage.setItem('submittedMessage', message);
      }
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  return (
    <>
      <section>
        <div className="flex-shrink-0">
          <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
            <RxCross1 className="text-2xl text-white p-1" />
          </button>
        </div>
        <h1 className="text-2xl text-black font-serif ms-3 mt-5 mb-3">Fill your requirements</h1>

        <form className="max-w-md mx-3 mt-10" onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <textarea
                name="floating_message"
                id="floating_message"
                className="block py-4 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-custom-pink focus:outline-none focus:ring-0 focus:border-custom-pink peer"
                placeholder=" "
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <label
                htmlFor="floating_message"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-custom-pink peer-focus:dark:text-custom-pink peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Message
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="text-white bg-custom-pink hover:bg-custom-pink focus:ring-4 focus:outline-none focus:ring-custom-pink font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-custom-pink dark:hover:bg-custom-pink dark:focus:ring-custom-pink"
            disabled={!message}
          >
            Submit
          </button>
        </form>
        {responseMessage && <p className="text-slate-800  text-center mt-4">{responseMessage}</p>}
      </section>
    </>
  );
}

export default Form;
