function Banner() {
  return (
    <>
      <div className="relative w-full h-64 overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="https://bandbaajabaarati.com/user/images/home.mp4"
          autoPlay
          loop
          muted
          height="100px"
        />
        {/* <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-6xl lg:text-8xl font-bold">Welcome to Our Event</h1>
        </div> */}
      </div>
    </>
  );
}

export default Banner;