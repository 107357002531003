import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { scrollToTop } from '../utils/scrollToTop';
import { FaUserCircle } from 'react-icons/fa';
import { MdOutlinePhoneIphone } from 'react-icons/md';
import { CiMail } from 'react-icons/ci';
import config from '../config';

function Profile() {
  const navigate = useNavigate();

  const topele = useRef(null);
  const [userDetails, setUserDetails] = useState({
    name: '',
    phone_number: '',
    email: '',
  });

  // Load user details from sessionStorage on component mount
  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem('userDetails');
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  }, []);

  // Save user details to sessionStorage whenever userDetails changes
  useEffect(() => {
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
  }, [userDetails]);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    axios.post(`${config.API_BASE_URL}/profile`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setUserDetails(response.data.profile);
        // console.log(response)
      })
      .catch(error => {
        console.error('There was an error fetching the user details!', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem('token');

    axios.put(`${config.API_BASE_URL}/profile`, userDetails, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        alert('User details updated successfully!');
        setUserDetails(response.data.profile);
        console.log(response)
      })
      .catch(error => {
        console.error('There was an error updating the user details!', error);
      });
  };

  const handleClose = () => {
    navigate(-1);
  };

  // console.log(userDetails.profile.email)

  return (
    <>

      <div className="shadow-lg" ref={topele}>
        <div className="flex items-center">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
        </div>
      </div>


      <h2 className="mt-5 font-bold text-2xl font-serif text-center mx-3">My Profile</h2>




      <form className="max-w-sm mx-3 mt-5" onSubmit={handleFormSubmit}>
        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
        <div className="flex mb-4">
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-custom-pink border border-e-0 border-custom-pink rounded-s-md dark:bg-pink-600 dark:text-pink-400 dark:border-black">
            <FaUserCircle className="text-lg text-white" />
          </span>
          <input
            type="text"
            id="name"
            name="name"
            className="rounded-none rounded-e-lg bg-pink-50 border border-pink-300 text-pink-900 block flex-1 min-w-0 w-full text-sm p-2.5 dark:bg-pink-700 dark:border-pink-600 dark:placeholder-pink-400 dark:text-white"
            placeholder="Enter your name"
            value={userDetails.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <label htmlFor="phone_number" className="block text-sm font-medium text-gray-900 dark:text-white">Mobile No.</label>
        <div className="flex mt-2 mb-4">
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-custom-pink border border-e-0 border-custom-pink rounded-s-md dark:bg-pink-600 dark:text-pink-400 dark:border-pink-600">
            <MdOutlinePhoneIphone className="text-lg text-white" />
          </span>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            className="rounded-none rounded-e-lg bg-pink-50 border border-pink-300 text-pink-900 block flex-1 min-w-0 w-full text-sm p-2.5 dark:bg-pink-700 dark:border-pink-600 dark:placeholder-pink-400 dark:text-white"
            placeholder="Enter your mobile number"
            value={userDetails.phone_number}
            onChange={handleInputChange}
            maxLength="10"
            required
          />
        </div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-white">Email</label>
        <div className="flex mt-2 mb-4">
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-custom-pink border border-e-0 border-custom-pink rounded-s-md dark:bg-pink-600 dark:text-pink-400 dark:border-pink-600">
            <CiMail className="text-lg text-white" />
          </span>
          <input
            type="email"
            id="email"
            name="email"
            className="rounded-none rounded-e-lg bg-pink-50 border border-pink-300 text-pink-900 block flex-1 min-w-0 w-full text-sm p-2.5 dark:bg-pink-700 dark:border-pink-600 dark:placeholder-pink-400 dark:text-white"
            placeholder="Enter your email"
            value={userDetails.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-custom-pink rounded-lg  focus:ring-opacity-50">
          Add
        </button>
      </form>
    </>
  );
}

export default Profile;
