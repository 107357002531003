import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Getstart() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate()
  useEffect(() => {
    if (token) {
      navigate("/home")
    }
  }, [token])

  useEffect(() => {
    // Custom carousel logic
    const carousel = document.getElementById('carouselExampleCaptions');
    const indicators = carousel.querySelectorAll('[data-slide-to]');
    const items = carousel.querySelectorAll('.carousel-item');
    let currentIndex = 0;

    function showSlide(index) {
      items.forEach((item, i) => {
        item.classList.toggle('hidden', i !== index);
        item.classList.toggle('block', i === index);
      });
      indicators.forEach((indicator, i) => {
        indicator.classList.toggle('opacity-100', i === index);
        indicator.classList.toggle('opacity-50', i !== index);
      });
    }

    function nextSlide() {
      currentIndex = (currentIndex + 1) % items.length;
      showSlide(currentIndex);
    }

    function prevSlide() {
      currentIndex = (currentIndex - 1 + items.length) % items.length;
      showSlide(currentIndex);
    }

    carousel.querySelector('[data-slide="next"]').addEventListener('click', nextSlide);
    carousel.querySelector('[data-slide="prev"]').addEventListener('click', prevSlide);
    indicators.forEach((indicator, index) => {
      indicator.addEventListener('click', () => showSlide(index));
    });

    showSlide(currentIndex); // Initialize the carousel

    // Autoplay logic
    const intervalId = setInterval(nextSlide, 3000); // Change slide every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);



  return (
    <div id="carouselExampleCaptions" className="relative">
      {/* <!--Carousel indicators--> */}
      <div className="absolute bottom-0 left-0 right-0 z-2 mx-[15%] mb-4 flex list-none justify-center p-0">
        <button
          type="button"
          data-slide-to="0"
          className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer bg-white p-0 opacity-50 transition-opacity duration-6000 ease-in-out"
          aria-label="Slide 1"></button>
        <button
          type="button"
          data-slide-to="1"
          className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer bg-white p-0 opacity-50 transition-opacity duration-6000 ease-in-out"
          aria-label="Slide 2"></button>
        <button
          type="button"
          data-slide-to="2"
          className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer bg-white p-0 opacity-50 transition-opacity duration-6000 ease-in-out"
          aria-label="Slide 3"></button>
      </div>

      {/* <!--Carousel items--> */}
      <div className="relative w-full overflow-hidden">
        {/* <!--First item--> */}
        <div className="carousel-item hidden w-full h-screen transition-transform duration-600 ease-in-out">
          <img src="assets/images/carsoule1.jpg" className="block w-full h-screen object-cover" alt="..." />
          <div className="absolute inset-x-[5%] bottom-5 py-5 text-center text-white md:block bg-black bg-opacity-50 rounded-lg">
            <h2 className="text-3xl my-2 font-bold bg-clip-text font-serif">
              Wedding planning made easy
            </h2>
            <p className="my-1">Find the right wedding vendors from 1.5L+ vendors across 20 categories</p>
            <Link to="/login">
              <button type="button" className="focus:outline-none text-white bg-custom-pink font-medium rounded-lg text-l px-10 py-2.5 me-2 mb-2 mt-10">
                GET STARTED
              </button>
            </Link>
          </div>
        </div>
        {/* <!--First item--> */}
        <div className="carousel-item hidden w-full h-screen transition-transform duration-600 ease-in-out">
          <img src="assets/images/carsoule2.jpg" className="block w-full h-screen object-cover" alt="..." />
          <div className="absolute inset-x-[5%] bottom-5 py-5 text-center text-white md:block bg-black bg-opacity-50 rounded-lg">
            <h2 className="text-3xl my-2 font-bold bg-clip-text font-serif">
              Wedding planning made easy
            </h2>
            <p className="my-1">Find the right wedding vendors from 1.5L+ vendors across 20 categories</p>
            <Link to="/login">
              <button type="button" className="focus:outline-none text-white bg-custom-pink font-medium rounded-lg text-l px-10 py-2.5 me-2 mb-2 mt-10">
                GET STARTED
              </button>
            </Link>
          </div>
        </div>
        {/* <!--First item--> */}
        <div className="carousel-item hidden w-full h-screen transition-transform duration-600 ease-in-out">
          <img src="assets/images/img24.jpg" className="block w-full h-screen object-cover" alt="..." />
          <div className="absolute inset-x-[5%] bottom-5 py-5 text-center text-white md:block bg-black bg-opacity-50 rounded-lg">
            <h2 className="text-3xl my-2 font-bold bg-clip-text font-serif">
              Wedding planning made easy
            </h2>
            <p className="my-1">Find the right wedding vendors from 1.5L+ vendors across 20 categories</p>
            <Link to="/login">
              <button type="button" className="focus:outline-none text-white bg-custom-pink font-medium rounded-lg text-l px-10 py-2.5 me-2 mb-2 mt-10">
                GET STARTED
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* <!--Carousel controls - prev item--> */}
      <button
        className="absolute bottom-0 left-0 top-0 z-1 flex w-[15%] items-center justify-center p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-in-out hover:opacity-90 focus:opacity-90"
        type="button"
        data-slide="prev">
        <span className="inline-block h-8 w-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </span>
        <span className="sr-only">Previous</span>
      </button>
      {/* <!--Carousel controls - next item--> */}
      <button
        className="absolute bottom-0 right-0 top-0 z-1 flex w-[15%] items-center justify-center p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-in-out hover:opacity-90 focus:opacity-90"
        type="button"
        data-slide="next">
        <span className="inline-block h-8 w-8">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
}

export default Getstart;
