import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { PiShareFatLight } from "react-icons/pi";
import { IoCheckmarkOutline } from "react-icons/io5";
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from '../config';
import { htmlToText } from 'html-to-text';

function Blog() {
  const navigate = useNavigate();
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })


  const handleClose = () => {
    navigate(-1);
  };


  const [blogdata, setBlogdata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      const blogId = sessionStorage.getItem('blog_id');
      const token = sessionStorage.getItem('token');


      if (!blogId) {
        setError('blog ID not found in session storage');
        setLoading(false);
        return;
      }

      if (!token) {
        setError('Token not found in session storage');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${config.API_BASE_URL}/blog`,
          { id: blogId },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const plainText = htmlToText(response.data, {
          wordwrap: 130
        });
        setBlogdata(response.data);
        // console.log(response)
      } catch (err) {
        setError('Error fetching vendor data');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, []);

  if (loading) {
    return <div className="text-center mt-20">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-20">{error}</div>;
  }



  return (
    <>
      <div className="shadow-xl bg-pink-100 w-full fixed top-0 z-10" ref={topele}>
        <div className="flex  items-center ">
          <div className="flex items-center">
            <button onClick={handleClose} className="bg-custom-pink border m-2 p-2.5 rounded-full">
              <MdOutlineArrowBackIos className="text-2xl text-white p-1" />
            </button>
          </div>
          <div className="flex items-center  ">

          </div>
        </div>
      </div>

      <div>
        <h2 className="font-bold text-2xl font-serif mx-3 mt-20">{blogdata.blogs.title} </h2>
        <div className="flex ms-3 my-3">
          <img src="assets\images\wapp.png" alt="" width="20px" className="me-2" />
          <img src="assets\images\facebook.png" alt="" width="20px" className="me-2" />
          <img src="assets\images\pinterest.png" alt="" width="20px" className="me-2" />
          <img src="assets\images\twitter-icon.png" alt="" width="20px" className="me-2" />
        </div>
        <p className="ms-3 text-sm">by <span className=" text-custom-pink"> Shivani Singh</span></p>

        <div>
          <div className="relative h-64 w-full mt-2">
            <div className="absolute inset-0 bg-cover bg-center " style={{ backgroundImage: `url("https://bandbaajabaarati.com/blog_images/${blogdata.blogs.image}")` }}></div>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute bottom-2 right-2 z-10">
              <div className="flex items-center">
                <button className="bg-slate-100 text-black text-base p-1 rounded-3xl flex items-center">
                  <PiShareFatLight />
                </button>
                <img src="assets/images/pinterest.png" alt="" width="23px" className="ml-3" />
              </div>
            </div>
          </div>
          <p className="text-center font-semibold" style={{ fontSize: "11px" }}>Source <span className=" text-custom-pink"> Through The Glass Paris</span></p>
          <div>

            <p className="mx-3 mt-4 overflow-hidden" dangerouslySetInnerHTML={{ __html: blogdata.blogs.description }} />
          </div>
        </div>
      </div>



    </>
  );
}

export default Blog;