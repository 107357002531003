import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Search = () => {
  const [activeTab, setActiveTab] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="fixed inset-0 bg-white z-50 p-4 overflow-auto">
      <div className="flex items-center justify-between mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search for Vendors, city, ideas..."
          className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-custom-pink"
        />
        <AiOutlineClose className="text-xl cursor-pointer ml-2" onClick={handleClose} />
      </div>
      <div className="flex justify-around mb-4">
        {['All', 'Vendors', 'Blog', 'Tags'].map((tab) => (
          <button
            key={tab}
            onClick={() => handleTabChange(tab)}
            className={`px-4 py-2 ${activeTab === tab ? 'border-b-2 border-custom-pink' : 'text-gray-500'}`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Recent Searches</h3>
        <div>
          <div className="flex items-center mb-2">
            <img src="assets/images/img5.jpg" alt="" className="rounded-full mr-2" style={{ width: "50px", height: "50px" }} />
            <div>
              <p className="text-sm font-medium">Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim harum velit eligendi voluptatibus debitis recusandae!</p>
              <p className="text-xs text-gray-500">Lorem, ipsum dolor. • 26jul2024</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Suggestions for You</h3>
        <div>
          <div className="flex items-center mb-2">
            <img src="assets/images/img4.jpg" alt="" className="rounded-full mr-2" style={{ width: "50px", height: "50px" }} />
            <div>
              <p className="text-sm font-medium">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium labore alias reiciendis, optio fuga consequatur?</p>
              <p className="text-xs text-gray-500">Lorem, ipsum dolor. • 24jul2024</p>
            </div>
          </div>
          <div className="flex items-center mb-2">
            <img src="assets/images/img4.jpg" alt="" className="rounded-full mr-2" style={{ width: "50px", height: "50px" }} />
            <div>
              <p className="text-sm font-medium">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium labore alias reiciendis, optio fuga consequatur?</p>
              <p className="text-xs text-gray-500">Lorem, ipsum dolor. • 24jul2024</p>
            </div>
          </div>
          <div className="flex items-center mb-2">
            <img src="assets/images/img4.jpg" alt="" className="rounded-full mr-2" style={{ width: "50px", height: "50px" }} />
            <div>
              <p className="text-sm font-medium">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium labore alias reiciendis, optio fuga consequatur?</p>
              <p className="text-xs text-gray-500">Lorem, ipsum dolor. • 24jul2024</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
